import {
  Fragment,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import tw from "tailwind-styled-components";
import Icon from "@c/icons";

const Overlay = tw.div`fixed inset-0 bg-black/40 backdrop-blur-sm`;

const Container = tw.div`
  fixed
  inset-0
  flex items-center
  justify-center
  p-4
`;

const Content = tw.div`mx-auto overflow-hidden bg-white rounded-b-lg p-5`;

const Header = tw.div`flex justify-between bg-modal-header-scene text-modal-header-label font-semibold rounded-t-lg p-5`;

const Close = tw.div`cursor-pointer`;

const Modal = forwardRef((props: IModal, ref) => {
  const { header, trigger, content, width } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const closeModal = useCallback(() => setIsOpen(false), []);

  useImperativeHandle(
    ref,
    () => {
      return {
        close: closeModal,
      };
    },
    [closeModal]
  );

  return (
    <>
      <div onClick={toggleModal}>{trigger}</div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={toggleModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Overlay />
          </Transition.Child>

          <Container>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={width}>
                {header && (
                  <Header>
                    {header}
                    <Close onClick={() => toggleModal()}>
                      <Icon.Clear />
                    </Close>
                  </Header>
                )}
                <Content>{content}</Content>
              </Dialog.Panel>
            </Transition.Child>
          </Container>
        </Dialog>
      </Transition>
    </>
  );
});

export default Modal;
