import { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";

import Modal from "@c/modal";
import Notify from "@c/notify";
import Button from "@c/button";
import { useDeleteOffer } from "@/hooks/use-loan";
// import { useNavigate } from "react-router-dom";

const Container = tw.div`flex flex-col gap-y-5`;

const Heading = tw.p`text-sm font-serif text-modal-make-offer-label`;

const DeleteOffer = ({ id, loanId }: IDeleteOffer) => {
  const [_deletingId, setDeletingId] = useState<string | null>(null);

  // const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    mutate,
    isLoading,
    isError,
    isSuccess: isDeleteOfferSuccess,
    error,
  } = useDeleteOffer();

  const deleteOffer = () => {
    setDeletingId(_deletingId);
    mutate(id);
  };

  useEffect(() => {
    isError &&
      error instanceof AxiosError &&
      Notify.error(JSON.parse(error.response?.data).message);

    setDeletingId(null);
  }, [isError, error]);

  useEffect(() => {
    isDeleteOfferSuccess &&
      Notify.success("Offer has been successfully deleted.");
    isDeleteOfferSuccess && queryClient.invalidateQueries(["loan", loanId]);
    setDeletingId(null);
  }, [id, isDeleteOfferSuccess, queryClient, loanId]);

  return (
    <Modal
      header="Delete Offer"
      trigger={
        <Button isLoading={false} variant="primary" width="w-[120px]">
          Delete
        </Button>
      }
      width="w-[400px]"
      content={
        <Container>
          <Heading>Are you sure you want to delete this offer?</Heading>

          <Button
            isLoading={isLoading}
            variant="secondary"
            width="w-full"
            onClick={() => deleteOffer()}
          >
            Delete Offer
          </Button>
        </Container>
      }
    />
  );
};

export default DeleteOffer;
