import { StorageKeys } from "@cn/storage-keys";
import adminRoles from "@cn/admin-roles.json";

const storage: IStorage | IStorageUtility = {};
const utilities: IStorage = {}

storage.setToken = (value: string) => {
  return localStorage.setItem(StorageKeys.TOKEN, value);
};

storage.setUser = (value: object) => {
  const user = JSON.stringify(value);
  return localStorage.setItem(StorageKeys.USER, user);
};

storage.setRoles = (value: string[]) => {
  const roles = JSON.stringify(value);
  return localStorage.setItem(StorageKeys.ROLES, roles);
};

storage.setPermissions = (value: string[]) => {
  const permissions = JSON.stringify(value);
  return localStorage.setItem(StorageKeys.PERMISSIONS, permissions);
};

storage.setCountry = (value: string) => {
  return localStorage.setItem(StorageKeys.COUNTRY, value);
};

storage.getToken = () => {
  const token = localStorage.getItem(StorageKeys.TOKEN);
  return token || null;
};

storage.getUser = () => {
  const user = localStorage.getItem(StorageKeys.USER);
  return user ? JSON.parse(user) : null;
};

storage.getRoles = () => {
  const roles = localStorage.getItem(StorageKeys.ROLES);
  return roles ? JSON.parse(roles) : null;
};

storage.getPermissions = () => {
  const permissions = localStorage.getItem(StorageKeys.PERMISSIONS);
  return permissions ? JSON.parse(permissions) : null;
};

storage.getCountry = () => {
  const token = localStorage.getItem(StorageKeys.COUNTRY);
  return token || null;
};

storage.clearToken = () => {
  return localStorage.removeItem(StorageKeys.TOKEN);
};

storage.clearAll = () => {
  return localStorage.clear();
};

utilities.isAdmin = () => {
  const roles = storage.getRoles() || [];
  return adminRoles.some((role: string) => roles.includes(role));
};

storage.utilities = utilities;

export default storage;
