import { useEffect, useState } from "react";

const useMount = (isMounted: boolean | null, delay: number) => {
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;

    if (isMounted && !visibility) {
      setVisibility(true);
    } else if (!isMounted && visibility) {
      timerId = setTimeout(() => setVisibility(false), delay);
    }

    return () => clearTimeout(timerId);
  }, [isMounted, delay, visibility]);

  return visibility;
};

export const asideMounted = { animation: "asideIn 300ms" };
export const asideUnmounted = {
  animation: "asideOut 300ms",
  animationFillMode: "forwards",
};

export const controlMounted = { animation: "controlIn 300ms" };
export const controlUnmounted = (isCollapsible: boolean | null) => {
  return isCollapsible
    ? { animation: "controlOut 300ms", animationFillMode: "forwards" }
    : {
        animation: "controlFadeOut 240ms",
        animationFillMode: "forwards",
      };
};

export default useMount;
