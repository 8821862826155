import { Outlet } from "react-router-dom";
import shallow from "zustand/shallow";
import Layout from "@c/layout";
import Control from "@b/aside/control";
import Aside from "@c/aside";
import useMount, { asideMounted, asideUnmounted } from "@h/use-mount";
import asideState from "@s/aside";

const AdaptiveAsideLayout = () => {
  const [isVisible, isCollapsible, toggleVisibility] = asideState(
    (state) => [state.isVisible, state.isCollapsible, state.toggleVisibility],
    shallow
  );
  const isVisibleMounted = useMount(isVisible, 300);
  const isCollapsibleMounted = useMount(isCollapsible, 300);

  return (
    <Layout.Container>
      {isCollapsibleMounted && <Control onClick={toggleVisibility} />}
      {isVisibleMounted && (
        <Layout.Aside style={isVisible ? asideMounted : asideUnmounted}>
          <Aside />
        </Layout.Aside>
      )}
      <Layout.Main $isAsideVisible={isVisible}>
        <Outlet />
      </Layout.Main>
    </Layout.Container>
  );
};

export default AdaptiveAsideLayout;
