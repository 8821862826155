import { useQuery, useMutation } from "@tanstack/react-query";
import storage from "@l/storage";
import client from "@/lib/axios-client";

const loan = (loanId: string | undefined, isAdmin: boolean) => {
  const { partner } = storage?.getUser() || {};
  const { id: partnerId } = partner || {};

  if (isAdmin) {
    return client.get(`/origination/loans/${loanId}`);
  } else {
    return client.get(`/origination/partners/${partnerId}/loans/${loanId}`);
  }
};

const loanAssetMedia = (carId: string) => {
  const params = new URLSearchParams({
    car_id: carId,
  });

  return client.get("/inventory/car_media", { params });
};

const basicInspection = (carId: string) => {
  const params = new URLSearchParams({
    car_id: carId,
  });

  return client.get("/inspection", { params });
};

const getPPI = (cardId: string) => {
  return client.get(`/inspection?car_id=${cardId}&type=warranty`);
};

const detailedInspection = (carId: string) => {
  return client.get(`/inventory/car/${carId}/inspection/marketplace?full=true`);
};

const loanDocument = (loanId: string | undefined) => {
  const params = new URLSearchParams({
    loan_id: loanId || "",
  });

  return client.get("/origination/documents", { params });
};

const makeOffer = (data: IMakeOffer) => {
  return client.post("/origination/offers", data);
};

const deleteOffer = (offer_id: string) => {
  return client.delete(`/origination/offers/${offer_id}`);
};

const getAdditionalDocuments = (country: string) => {
  const params = new URLSearchParams({
    country: country || "NG",
  });
  return client.get("/origination/additionalDocuments", { params });
};

const requestEquity = (data: IRequestEquity) => {
  return client.post("/origination/equity", data);
};

export const useLoan = (loanId: string | undefined, isAdmin: boolean) => {
  return useQuery(["loan", loanId], () => loan(loanId, isAdmin), {
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};

export const useLoanAssetMedia = (carId: string, enabled: boolean) => {
  return useQuery(["loanAssetMedia", carId], () => loanAssetMedia(carId), {
    enabled,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useBasicInspection = (carId: string, enabled: boolean) => {
  return useQuery(["basicInspection", carId], () => basicInspection(carId), {
    enabled,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useDetailedInspection = (carId: string, enabled: boolean) => {
  return useQuery(
    ["detailedInspection", carId],
    () => detailedInspection(carId),
    {
      enabled,
      retry: false,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export const useGetPPI = (carId: string, enabled: boolean) => {
  return useQuery(["getPPI", carId], () => getPPI(carId), {
    enabled,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useLoanDocument = (
  loanId: string | undefined,
  enabled: boolean
) => {
  return useQuery(["loanDocument", loanId], () => loanDocument(loanId), {
    enabled,
    retry: false,
    refetchInterval: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export const useAdditionalDocuments = (country: string, enabled: boolean) => {
  return useQuery(
    ["AdditionalDocuments", country],
    () => getAdditionalDocuments(country),
    {
      enabled,
      retry: false,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export const useMakeOffer = () => {
  return useMutation(makeOffer);
};

export const useDeleteOffer = () => {
  return useMutation(deleteOffer);
};

export const useRequestEntity = () => {
  return useMutation(requestEquity);
};

const addAdditionalDocument = (data: AdditionalDocumentPayload) => {
  return client.post("/origination/additionalDocuments", data);
};

const requestDocuments = (data: RequestDocuments) => {
  return client.post("/origination/documents/request", data);
};

export const useRequestDocument = () => {
  return useMutation(requestDocuments);
};

export const useAddAdditionalDocument = () => {
  return useMutation(addAdditionalDocument);
};
