const download = (path: string, filename: string) => {
  const pathSplitLength = path.split(".").length;
  const ext = path.split(".")[pathSplitLength - 1];

  fetch(path)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.setAttribute("download", `${filename}.${ext}`);
      link.setAttribute("href", url);
      link.click();
    });
};

export default download;
