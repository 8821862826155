import React from "react";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {
  Routes,
  Route,
  unstable_HistoryRouter as HistoryRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "@l/history";
import AdaptiveAsideLayout from "@lo/adaptive-aside";
import RequireAuthentication from "@c/require-authentication";
import Redirect from "@p/redirect";
import SignIn from "@p/auth/sign-in";
import PendingApproval from "@p/loans/pending-approval";
import ProcessedApplication from "@p/loans/processed-application";
import LoanByID from "@p/loans/details/[ID]";
import NotFound from "@p/errors/not-found";
import asideState from "./states/aside";
import storage from "@l/storage";
import authState from "./states/auth";
import shallow from "zustand/shallow";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  environment: window.location.host,
  tracesSampleRate: 1.0,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const App = () => {
  const [setVisibility, setCollapsibility] = asideState(
    (state) => [state.setVisibility, state.setCollapsibility],
    shallow
  );

  const isLoggedIn =
    authState((state: IAuthState) => state.isLoggedIn) ||
    storage?.getUser() !== null;

  useEffect(() => {
    if (isLoggedIn && isLoggedIn !== null) {
      setVisibility(true);
      setCollapsibility(true);
    } else {
      setVisibility(false);
      setCollapsibility(false);
    }
  }, [isLoggedIn, setCollapsibility, setVisibility]);

  return (
    <>
      <HistoryRouter history={history}>
        <SentryRoutes>
          <Route element={<AdaptiveAsideLayout />}>
            <Route
              path="/"
              element={<Redirect path="/loans/pending-approval" />}
            />
            <Route path="auth">
              <Route path="sign-in" element={<SignIn />} />
            </Route>
            <Route path="loans">
              <Route
                path=""
                element={<Redirect path="/loans/pending-approval" />}
              />
              <Route
                path="pending-approval"
                element={
                  <RequireAuthentication>
                    <PendingApproval />
                  </RequireAuthentication>
                }
              />
              <Route
                path="processed-application"
                element={
                  <RequireAuthentication>
                    <ProcessedApplication />
                  </RequireAuthentication>
                }
              />
              <Route
                path="pending-approval/:id"
                element={
                  <RequireAuthentication>
                    <LoanByID />
                  </RequireAuthentication>
                }
              />
              <Route
                path="processed-application/:id"
                element={
                  <RequireAuthentication>
                    <LoanByID />
                  </RequireAuthentication>
                }
              />
            </Route>
          </Route>
          <Route element={<AdaptiveAsideLayout />}>
            <Route path="*" element={<NotFound />} />
          </Route>
        </SentryRoutes>
      </HistoryRouter>

      <ToastContainer />
    </>
  );
};

export default App;
