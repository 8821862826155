import tw from "tailwind-styled-components";
import Icon from "@c/icons";

const Container = tw.div`flex flex-1 h-12 bg-white rounded-md px-5 gap-x-3 items-center`;

const Input = tw.input`bg-transparent w-full outline-none text-inputs-search-input font-normal placeholder:text-inputs-search-placeholder`;

const Clear = tw.div`cursor-pointer`;

const SearchInput = ({
  placeholder,
  name,
  onChange,
  value,
  onClear,
}: ISearchInput) => {
  return (
    <Container>
      <Icon.Search />
      <Input
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        value={value}
      />
      {value && (
        <Clear onClick={onClear}>
          <Icon.Clear />
        </Clear>
      )}
    </Container>
  );
};

export default SearchInput;
