import { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosError } from "axios";
import {
  Container,
  Header,
  DateGroup,
  Group,
  DateGroupTitle,
} from "@p/loans/styles/pending-approval";
import SearchInput from "@b/inputs/search";
import QueryError from "@b/query/error";
import QueryEmpty from "@b/query/empty";
import Button from "@c/button";
import ListSelect from "@c/list-select";
import Placeholder from "@c/placeholder";
import Pagination from "@c/pagination";
import Table from "@c/tables/default";
import { useLoans } from "@h/use-loans";
import { useProducts } from "@h/use-product";
import formatInt from "@u/format-int";
import formatDate from "@u/format-date";
import storage from "@l/storage";
import countries from "@cn/countries.json";
import dateFilters from "@cn/date-filters.json";
import { getItem } from "@/utils/extract-items";

import "react-datepicker/dist/react-datepicker.css";

const PendingApproval = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState<any[]>([]);
  const [params, setParams] = useSearchParams();
  const country = storage.getCountry();
  const { partner } = storage?.getUser() || {};
  const { id: partnerId } = partner || {};

  const isAdmin = storage.utilities.isAdmin();

  useEffect(() => {
    document.title = "ACM | Pending Approval";
    const queryCountry = params.get("country");
    const userCountry = queryCountry || country;

    storage.setCountry(userCountry);
    params.set("country", userCountry);

    setParams(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading, isSuccess, isError, isRefetching, error, data, refetch } =
    useLoans(params, country, isAdmin, partnerId, false);

  const { data: productData } = useProducts(country);

  useEffect(() => {
    if (productData) {
      const products = JSON.parse(productData.data).result;

      const filteredProducts = products.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));

      setProducts(filteredProducts);
    }
  }, [productData]);

  const getProductName = (id: string | null) => {
    return products.filter((item) => item.value === id)[0]?.label;
  };

  const getCountryName = (id: string | null) => {
    return countries.filter((item) => item.value === id)[0]?.label;
  };

  const getDateFilterName = (id: string | null) => {
    return dateFilters.filter((item) => item.value === id)[0]?.label;
  };

  const getDateValue = (id: string | null) => {
    let value;

    if (id) {
      value = new Date(Number(id));
    } else {
      value = null;
    }

    return value;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    params.set(name, value);
    setParams(params);
  };

  const handleSelectChange = (name: string, value: string) => {
    params.set(name, value);

    if (name === "country") storage.setCountry(value);

    setParams(params);

    if (name !== "date_filter_by") refetch();
  };

  const handlePageChange = (page: number) => {
    params.set("current_page", page.toString());

    setParams(params);
    refetch();
  };

  const handleDateChange = (date: Date, type: string) => {
    const milliseconds = new Date(date).valueOf();

    if (type === "from") {
      params.set("from_date", milliseconds.toString());
    } else if (type === "to") {
      params.set("to_date", milliseconds.toString());
    }

    setParams(params);
  };

  const clearFilterParams = () => {
    params.delete("search");
    params.delete("product_id");

    params.set("country", country);
    setParams(params);
    refetch();
  };

  const clearDateFilterParams = () => {
    params.delete("date_filter_by");
    params.delete("from_date");
    params.delete("to_date");

    setParams(params);
    refetch();
  };

  const clearSearchParams = () => {
    params.delete("search");

    setParams(params);
    refetch();
  };

  const search = () => {
    const query = params.get("search");

    query && refetch();
  };

  useEffect(() => {
    params.forEach((key, value) => {
      [key, value] = [value, key];

      if (key === "current_page" && value === "1") {
        params.delete(key);
      }
      value === "" && params.delete(key);
    });

    setParams(params);
  }, [params, setParams]);

  return (
    <Container>
      <Group>
        <Header>
          <SearchInput
            placeholder="Search by Name and Request ID"
            name="search"
            onChange={handleInputChange}
            value={params.get("search") || ""}
            onClear={clearSearchParams}
          />

          <ListSelect
            name="product_id"
            isvalue={params.get("product_id")}
            selected={
              getProductName(params.get("product_id")) || "Product Type"
            }
            options={products}
            onChange={handleSelectChange}
            width="w-[170px]"
          />

          {isAdmin && (
            <ListSelect
              name="country"
              isvalue={params.get("country")}
              selected={getCountryName(params.get("country")) || "Country"}
              options={countries}
              onChange={handleSelectChange}
              width="w-[170px]"
            />
          )}

          <Button
            isLoading={false}
            variant="primary"
            width="w-[100.98px]"
            onClick={search}
          >
            Search
          </Button>

          <Button
            isLoading={false}
            variant="tertiary"
            onClick={clearFilterParams}
          >
            Reset
          </Button>
        </Header>

        <DateGroup>
          <DateGroupTitle>Show only lead from:</DateGroupTitle>
          <ListSelect
            name="date_filter_by"
            isvalue={params.get("date_filter_by")}
            selected={
              getDateFilterName(params.get("date_filter_by")) ||
              "Filter Date By"
            }
            options={dateFilters}
            onChange={handleSelectChange}
            width="w-[170px]"
          />
          <DatePicker
            placeholderText="From Date"
            selected={getDateValue(params.get("from_date"))}
            onChange={(date: Date) => handleDateChange(date, "from")}
            className="flex flex-row relative h-12 gap-x-2 justify-between items-center px-4 py-3 bg-white text-inherit text-base rounded-md cursor-pointer capitalize w-[unset]"
          />
          <DatePicker
            placeholderText="To Date"
            selected={getDateValue(params.get("to_date"))}
            onChange={(date: Date) => handleDateChange(date, "to")}
            className="flex flex-row relative h-12 gap-x-2 justify-between items-center px-4 py-3 bg-white text-inherit text-base rounded-md cursor-pointer capitalize w-[unset]"
          />

          <Button isLoading={false} variant="primary" onClick={() => refetch()}>
            Apply
          </Button>

          <Button
            isLoading={false}
            variant="tertiary"
            onClick={clearDateFilterParams}
          >
            Reset
          </Button>
        </DateGroup>
      </Group>

      <Table.Container>
        <Table.Content>
          <Table>
            <thead>
              <tr>
                <Table.TH>Loan Created Date</Table.TH>
                <Table.TH>Loan Completed Date</Table.TH>
                <Table.TH>Loan ID</Table.TH>
                <Table.TH>Car</Table.TH>
                <Table.TH>Loan Applied</Table.TH>
                <Table.TH>Customer</Table.TH>
                <Table.TH>Product Type</Table.TH>
              </tr>
            </thead>

            {(isLoading || isRefetching || data) && (
              <Table.Tbody>
                {(isLoading || isRefetching) &&
                  Array(10)
                    .fill(null)
                    .map((_null, index) => (
                      <Table.TR key={index}>
                        <Table.TD>
                          <Placeholder align="center" />
                        </Table.TD>
                        <Table.TD>
                          <Placeholder align="center" />
                        </Table.TD>
                        <Table.TD>
                          <Placeholder align="center" />
                        </Table.TD>
                        <Table.TD>
                          <Placeholder align="center" />
                        </Table.TD>
                        <Table.TD>
                          <Placeholder align="center" />
                        </Table.TD>
                        <Table.TD>
                          <Placeholder align="center" />
                        </Table.TD>
                        <Table.TD>
                          <Placeholder align="center" />
                        </Table.TD>
                      </Table.TR>
                    ))}

                {!isLoading &&
                  !isRefetching &&
                  data &&
                  JSON.parse(data?.data)?.loanLeads?.map(
                    (loan: any, index: number) => (
                      <Table.TR
                        key={index}
                        onClick={() => {
                          navigate(`/loans/pending-approval/${loan.id}`);
                        }}
                      >
                        <Table.TD>
                          {formatDate(loan.firstSeen) || "---"}
                        </Table.TD>
                        <Table.TD>
                          {formatDate(loan.completedAt) || "---"}
                        </Table.TD>
                        <Table.TD>{loan.id || "---"}</Table.TD>
                        <Table.TD>{loan.carName || "---"}</Table.TD>
                        <Table.TD>{formatInt(loan.loanValue, true)}</Table.TD>
                        <Table.TD>
                          {getItem(loan.customer?.profile, "firstName")
                            ?.value ||
                            loan.firstName ||
                            "---"}{" "}
                          {getItem(loan.customer?.profile, "lastName")?.value ||
                            loan.lastName ||
                            "---"}
                        </Table.TD>
                        <Table.TD>{loan.productType || "---"}</Table.TD>
                      </Table.TR>
                    )
                  )}
              </Table.Tbody>
            )}
          </Table>

          {!isLoading &&
            !isRefetching &&
            data &&
            JSON.parse(data.data).loanLeads.length === 0 && <QueryEmpty />}

          {isError && error instanceof AxiosError && <QueryError />}
        </Table.Content>

        {!isLoading &&
          !isRefetching &&
          isSuccess &&
          JSON.parse(data.data).loanLeads.length > 0 && (
            <Pagination
              size={Math.ceil(
                JSON.parse(data?.data).pagination.total /
                  JSON.parse(data?.data).pagination.pageSize
              )}
              current={JSON.parse(data?.data).pagination.currentPage}
              onPageChange={handlePageChange}
            />
          )}
      </Table.Container>
    </Container>
  );
};

export default PendingApproval;
