import tw from "tailwind-styled-components";

const Container = tw.div`flex justify-center items-center h-full w-full`;

const Content = tw.div`flex flex-col text-center items-center gap-y-1`;

const IconContainer = tw.div`mb-3`;

const Label = tw.p`text-[17px] text-query-error-label`;

const QueryError = () => {
  return (
    <Container>
      <Content>
        <IconContainer>
          <svg
            width="216px"
            height="99px"
            viewBox="0 0 216 99"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Group 2</title>
            <g
              id="Symbols"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="Group-2" transform="translate(0.000000, 1.000000)">
                <path
                  d="M119,20 C122.865993,20 126,23.1340067 126,27 C126,30.8659933 122.865993,34 119,34 L183,34 C186.865993,34 190,37.1340067 190,41 C190,44.8659933 186.865993,48 183,48 L205,48 C208.865993,48 212,51.1340067 212,55 C212,58.8659933 208.865993,62 205,62 L186,62 C182.134007,62 179,65.1340067 179,69 C179,72.8659933 182.134007,76 186,76 L192,76 C195.865993,76 199,79.1340067 199,83 C199,86.8659933 195.865993,90 192,90 L140,90 C139.485125,90 138.983234,89.9444148 138.5,89.838913 C138.016766,89.9444148 137.514875,90 137,90 L46,90 C42.1340067,90 39,86.8659933 39,83 C39,79.1340067 42.1340067,76 46,76 L7,76 C3.13400674,76 0,72.8659933 0,69 C0,65.1340067 3.13400674,62 7,62 L47,62 C50.8659933,62 54,58.8659933 54,55 C54,51.1340067 50.8659933,48 47,48 L22,48 C18.1340067,48 15,44.8659933 15,41 C15,37.1340067 18.1340067,34 22,34 L62,34 C58.1340067,34 55,30.8659933 55,27 C55,23.1340067 58.1340067,20 62,20 L119,20 Z M209,76 C212.865993,76 216,79.1340068 216,83 C216,86.8659932 212.865993,90 209,90 C205.134007,90 202,86.8659932 202,83 C202,79.1340068 205.134007,76 209,76 Z"
                  id="Background"
                  fill="#F6F7FB"
                ></path>
                <g id="Group" transform="translate(53.000000, 0.000000)">
                  <path
                    d="M0,74.5 C0,62.6258783 9.84973526,53 22,53 C22.516677,53 23.0291955,53.0174056 23.536972,53.051651 C23.1841669,51.0877984 23,49.0654066 23,47 C23,28.2223186 38.2223186,13 57,13 C71.9910431,13 84.7160678,22.7019806 89.2386169,36.1694946 C90.310149,36.0574559 91.3982511,36 92.5,36 C109.344685,36 123,49.4314575 123,66 C123,81.7406092 110.675305,94.7273319 95,95.9781799 L95,96 L38.5078125,96 L22,96 C9.84973526,96 0,86.3741217 0,74.5 Z M33.9959717,96 L27.0313721,96"
                    id="Shape"
                    stroke="#30345E"
                    strokeWidth="2.2"
                    fill="#FFFFFF"
                    strokeLinecap="round"
                    strokeDasharray="0,0"
                  ></path>
                  <path
                    d="M113,82.7028692 C108.31728,88.4611199 101.085543,92.3674982 92.8469363,92.9812809 L92.8469363,93 L25.7430582,93 C17.6013485,93 4,89.5697028 4,74.5483438 C4,59.5269848 14.5741551,56.0966913 25.7430582,56.0966913 C26.2180038,56.0966913 26.6891261,56.1116253 27.1558901,56.1410152 C26.8315806,54.4556044 26.7745776,52.7184028 26.6622909,50.9473868 C25.9037948,38.9841752 31.1229358,23.9069668 46.630388,19 C46.6179565,19.4473288 46.6123453,19.8944292 46.6123453,20.3426254 C46.6123453,52.5657338 75.6328186,79.118006 113,82.7028692 Z M51.6142415,76.1948901 C51.6142415,79.1976323 54.0776091,81.6318362 57.1163273,81.6318362 C60.1550454,81.6318362 62.6184131,79.1976323 62.6184131,76.1948901 C62.6184131,73.1921478 60.1550454,70.7579439 57.1163273,70.7579439 C54.0776091,70.7579439 51.6142415,73.1921478 51.6142415,76.1948901 Z"
                    id="Shape"
                    fill="#F6F7FB"
                  ></path>
                  <path
                    d="M57.5,82 C54.4624338,82 52,79.5375662 52,76.5 C52,73.4624338 54.4624338,71 57.5,71 C60.5375662,71 63,73.4624338 63,76.5 C63,79.5375662 60.5375662,82 57.5,82 Z"
                    id="Oval"
                    stroke="#30345E"
                    strokeWidth="2.2"
                    strokeDasharray="0,0"
                  ></path>
                  <path
                    d="M42,65.0000076 L49,59.5068436 L42,54.2775879"
                    id="Line"
                    stroke="#30345E"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="0,0"
                  ></path>
                  <path
                    d="M66,65.0000076 L73,59.5068436 L66,54.2775879"
                    id="Line"
                    stroke="#30345E"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="0,0"
                    transform="translate(69.500000, 59.638798) scale(1, -1) rotate(-180.000000) translate(-69.500000, -59.638798) "
                  ></path>
                  <path
                    d="M80.4401855,23 C73.9312944,24.7225775 68.7919732,29.8128552 66.9998932,36.2931824"
                    id="Oval"
                    stroke="#30345E"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeDasharray="0,0"
                    transform="translate(73.720039, 29.646591) scale(1, -1) rotate(-180.000000) translate(-73.720039, -29.646591) "
                  ></path>
                  <path
                    d="M88,6 C86.3431457,6 85,4.65685427 85,3 C85,1.34314573 86.3431457,0 88,0 C89.6568543,0 91,1.34314573 91,3 C91,4.65685427 89.6568543,6 88,6 Z"
                    id="Oval"
                    stroke="#30345E"
                    strokeWidth="2"
                    strokeDasharray="0,0"
                  ></path>
                  <path
                    d="M119,22 C117.343146,22 116,20.6568543 116,19 C116,17.3431457 117.343146,16 119,16 C120.656854,16 122,17.3431457 122,19 C122,20.6568543 120.656854,22 119,22 Z"
                    id="Oval"
                    fill="#30345E"
                  ></path>
                  <path
                    d="M95.8843842,22.115551 L104.242615,13.7573242 L95.8843842,22.115551 Z M95.7573242,13.7573242 L104.115555,22.115551"
                    id="Line-5"
                    stroke="#30345E"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="0,0"
                  ></path>
                  <path
                    d="M1.40380859,37.5961914 L7.40380859,31.5961914 L1.40380859,37.5961914 Z M1.40380859,31.5961914 L7.40380859,37.5961914"
                    id="Line-4"
                    stroke="#30345E"
                    strokeWidth="2.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="0,0"
                  ></path>
                  <path
                    d="M15,25 C13.3431457,25 12,23.6568543 12,22 C12,20.3431457 13.3431457,19 15,19 C16.6568543,19 18,20.3431457 18,22 C18,23.6568543 16.6568543,25 15,25 Z"
                    id="Oval"
                    fill="#30345E"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </IconContainer>

        <Label>
          Something went wrong.
          <br />
          Please try again
        </Label>
      </Content>
    </Container>
  );
};

export default QueryError;
