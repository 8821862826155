import tw from "tailwind-styled-components";
import {
  ChevronLeftIcon,
  ChevronUpDownIcon,
  DocumentDuplicateIcon,
  ArrowRightOnRectangleIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  CheckIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  StarIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";

const Icon = tw``;

const ChevronLeft = tw(ChevronLeftIcon)`
  ${(props: IAsideControlCIcon) =>
    props.$isAsideVisible ? "rotate-0" : "rotate-180"}
    
  w-5 text-white duration-150`;

const ChevronUpDown = tw(ChevronUpDownIcon)`w-6 text-white cursor-pointer`;

const DocumentDuplicate = tw(
  DocumentDuplicateIcon
)`w-4 text-gray-400 hover:text-white cursor-pointer`;

const ArrowRightOnRectangle = tw(ArrowRightOnRectangleIcon)`w-5 text-white`;

const CheckCircle = tw(CheckCircleIcon)`w-[18px] text-green-400`;

const ChevronDown = tw(ChevronDownIcon)`
  ${(props: IAsideItemToggle) => (props.$isVisible ? "rotate-180" : "rotate-0")}
  w-5
  
  text-white
  duration-150
`;

const Funnel = tw(FunnelIcon)`w-5 text-button-variants-tertiary-icons-filter`;

const MagnifyingGlass = tw(
  MagnifyingGlassIcon
)`w-[22px] text-inputs-search-icons-search`;

const XMark = tw(XMarkIcon)`w-5 text-inputs-search-icons-clear`;

const Selector_Chevron = tw(
  ChevronUpDownIcon
)`h-6 text-list-select-selector-icon`;

const Selector_Check = tw(CheckIcon)`h-5`;

const PaginateBackward = tw(ChevronLeftIcon)`
  w-5
  text-pagination-button-icon
  text-inherit
`;

const PaginateForward = tw(ChevronRightIcon)`
  w-5
  text-pagination-button-icon
  text-inherit
`;

const PaginateFirst = tw(ChevronDoubleLeftIcon)`
  w-5
  text-pagination-button-icon
  text-inherit
`;

const PaginateLast = tw(ChevronDoubleRightIcon)`
  w-5
  text-pagination-button-icon
  text-inherit
`;

const Star = tw(
  StarIcon
)`w-4 fill-pages-loan-detail-favorite stroke-pages-loan-detail-favorite`;

const Plus = tw(PlusIcon)`w-5 text-inputs-search-icons-clear bg-yellow-400 rounded-full p-0.5 cursor-pointer`;

Icon.AsideControl = ChevronLeft;
Icon.ProfileOptions = ChevronUpDown;
Icon.ProfileCopy = DocumentDuplicate;
Icon.ProfileLogout = ArrowRightOnRectangle;
Icon.ProfileCopied = CheckCircle;
Icon.AsideItemToggle = ChevronDown;
Icon.Filter = Funnel;
Icon.Search = MagnifyingGlass;
Icon.Clear = XMark;
Icon.Selector = Selector_Chevron;
Icon.Check = Selector_Check;
Icon.PaginateBackward = PaginateBackward;
Icon.PaginateForward = PaginateForward;
Icon.PaginateFirst = PaginateFirst;
Icon.PaginateLast = PaginateLast;
Icon.Star = Star;
Icon.Add = Plus;

export default Icon;
