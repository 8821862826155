import { useEffect } from "react";

const NotFound = () => {
  useEffect(() => {
    document.title = "ACM | Page Not found";
  }, []);

  return <>NotFound</>;
};

export default NotFound;
