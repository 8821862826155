import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";

const loans = (
  searchParams: URLSearchParams,
  country: string,
  isAdmin: boolean,
  partnerId: string,
  isProcessed: boolean,
) => {
  const userParams = Object.fromEntries([...searchParams]);

  if (isAdmin) {
    const params = new URLSearchParams({
      country,
      sort_by: 'updatedAt',
      ...userParams,
      page_size: "10",
      is_visible_to_partners: "true",
      is_loan_processed: isProcessed.toString(),
    });

    return client.get("/origination/loans", { params });
  } else {
    const params = new URLSearchParams({
      country,
      sort_by: 'updatedAt',
      ...userParams,
      page_size: "10",
      is_visible_to_partners: "true",
      expired: "false",
      is_partner_loan_processed: isProcessed.toString(),
    });

    return client.get(`/origination/loans/partners/${partnerId}`, {
      params,
    });
  }
};

export const useLoans = (
  searchParams: URLSearchParams,
  country: string,
  isAdmin: boolean,
  partnerId: string,
  isProcessed: boolean,
) => {
  return useQuery(
    ["loans", partnerId],
    () => loans(searchParams, country, isAdmin, partnerId, isProcessed),
    {
      retry: false,
      refetchInterval: Infinity,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );
};
