import tw from "tailwind-styled-components";

const Container = tw.button`
${(props: Partial<IButton>) =>
  props.variant === "primary"
    ? "bg-button-variants-primary-scene"
    : props.variant === "secondary"
    ? "bg-button-variants-secondary-scene"
    : props.variant === "tertiary"
    ? "bg-white"
    : undefined}

${(props: Partial<IButton>) =>
  props.variant === "primary"
    ? "hover:bg-button-variants-primary-scene/90"
    : props.variant === "secondary"
    ? "hover:bg-button-variants-secondary-scene/80"
    : props.variant === "tertiary"
    ? "hover:bg-white/60"
    : undefined}
    
${(props: Partial<IButton>) =>
  props.variant === "primary"
    ? "text-white"
    : props.variant === "secondary"
    ? "text-button-variants-secondary-text"
    : props.variant === "tertiary"
    ? "text-button-variants-tertiary-text"
    : undefined}
            
${(props: Partial<IButton>) =>
  props.variant === "primary"
    ? "disabled:bg-button-variants-primary-scene/40"
    : props.variant === "secondary"
    ? "disabled:bg-button-variants-secondary-scene/50"
    : props.variant === "tertiary"
    ? "disabled:bg-white/10"
    : undefined}

${(props: Partial<IButton>) => props.width || "w-[unset]"}

flex flex-row h-12 gap-x-2 justify-center items-center px-6 py-3 text-base font-bold rounded-md disabled:cursor-not-allowed tracking-wide`;

const Loader = tw.div`
${(props: Partial<IButtonLoader>) =>
  props.variant === "primary"
    ? "border-b-white"
    : props.variant === "secondary"
    ? "border-b-button-variants-secondary-loading-scene"
    : props.variant === "tertiary"
    ? "border-b-button-variants-tertiary-loading-scene"
    : undefined}
    
inline-block w-6 h-6 border-[1.7px] border-l-transparent border-r-transparent border-t-transparent rounded-full animate-spin`;

const Button = ({
  isLoading,
  icon,
  variant,
  width,
  onClick,
  children,
}: IButton) => {
  return (
    <Container
      disabled={isLoading}
      width={width}
      variant={variant}
      onClick={onClick}
      {...(!onClick && { type: "submit" })}
    >
      {isLoading ? (
        <Loader variant={variant} />
      ) : (
        <>
          {icon}
          {children}
        </>
      )}
    </Container>
  );
};

export default Button;
