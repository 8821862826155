import { useCallback, useEffect, useRef, useState } from "react";
import tw from "tailwind-styled-components";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "@c/modal";
import Notify from "@c/notify";
import Button from "@c/button";
import { useAdditionalDocuments, useRequestDocument } from "@/hooks/use-loan";
import { useNavigate } from "react-router-dom";
import storage from "@l/storage";
import AddAdditionalDocuments from "./addAdditionalDocuments";

const Container = tw.div`flex flex-col gap-y-5`;

const Form = tw.form`flex flex-col gap-y-5`;

const FormItem = tw.span`flex flex-col gap-y-2`;

const ScrollView = tw.div`overflow-auto max-h-96`;

const CheckContainer = tw.div`flex items-center mb-4`;

const Label = tw.label`ml-2 text-base font-medium text-black-900 dark:text-black-300`;

const Input = tw.input`w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-100 focus:ring-blue-100 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600`;

const Header = tw.div`flex flex-1 ml-4 self-center`;

const RequestAdditionalDocuments = ({
  loanid,
  partnerid,
}: IRejectOfferComp) => {
  const [selected, setSelected]: any[] = useState({});
  const country = storage.getCountry();
  const modalRef = useRef<ModalRef>(null);

  const { isSuccess: isLDSuccess, data: ldData } = useAdditionalDocuments(
    country,
    true
  );

  const schema = yup
    .object({
      documents: yup
        .array()
        .of(yup.boolean())
        .test({
          name: "one-true",
          message: "Required",
          test: (val) => !val?.every((dat) => dat === false),
        }),
    })
    .required();

  const { register, handleSubmit } = useForm<RequestDocuments>({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, isSuccess, error } = useRequestDocument();

  const handleSignIn = () => {
    const documents: AdditionalDocument[] = Object.values(selected);
    mutate({ documents, loan_id: loanid, partner_id: partnerid });
  };

  useEffect(() => {
    isError &&
      error instanceof AxiosError &&
      Notify.error(JSON.parse(error.response?.data).message);
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      Notify.success("Documents has been successfully Requested.");
      queryClient.invalidateQueries(["loanDocument", loanid]);
      modalRef.current?.close();
    }
  }, [isSuccess, navigate, loanid, queryClient]);

  const handleChange = useCallback(
    (data: AdditionalDocument) => {
      if (selected?.hasOwnProperty(data?.id)) {
        const newData = { ...selected };
        delete newData?.[data?.id];
        setSelected(newData);
      } else {
        setSelected({
          ...selected,
          [data.id]: data,
        });
      }
    },
    [selected]
  );

  return (
    <Modal
      ref={modalRef}
      header={
        <>
          {"Request For Additional Document"}
          <Header>
            <AddAdditionalDocuments />
          </Header>
        </>
      }
      trigger={
        <Button isLoading={false} variant="primary" width="w-[250px]">
          Request For Additional Document
        </Button>
      }
      width="w-[400px]"
      content={
        <Container>
          <Form onSubmit={handleSubmit(handleSignIn)} autoComplete="off">
            <ScrollView>
              {isLDSuccess &&
                ldData &&
                JSON?.parse?.(ldData?.data ?? "")?.documents?.map(
                  (data: AdditionalDocument, index: number) => (
                    <FormItem>
                      <CheckContainer key={data?.id}>
                        <Input
                          id={`checkBox${data?.id}`}
                          type="checkbox"
                          {...register(`documents.${index}`)}
                          value={selected?.hasOwnProperty(data?.id)}
                          onChange={() => handleChange(data)}
                        />

                        <Label htmlFor={`checkBox${data?.id}`}>
                          {data?.name}
                        </Label>
                      </CheckContainer>
                    </FormItem>
                  )
                )}
            </ScrollView>

            <Button isLoading={isLoading} variant="secondary" width="w-full">
              Submit
            </Button>
          </Form>
        </Container>
      }
    />
  );
};

export default RequestAdditionalDocuments;
