import { Fragment } from "react";
import tw from "tailwind-styled-components";
import Icon from "@c/icons";
import { Listbox, Transition } from "@headlessui/react";

const Container = tw.div`
${(props: Partial<IListSelect>) => props.width}

${(props: Partial<IListSelect>) =>
  props.isvalue
    ? "text-list-select-selector-label"
    : "text-list-select-selector-placeholder"}

relative mt-1 font-normal`;

const Selector = tw.div`flex flex-row relative h-12 gap-x-2 justify-between items-center px-4 py-3 bg-white text-inherit text-base rounded-md cursor-pointer capitalize`;

const Label = tw.div`overflow-hidden truncate`;

const Options = tw.div`absolute mt-2 h-auto max-h-60 w-max overflow-auto rounded-md bg-white outline-none z-50 border border-list-select-options-border`;

const Option = tw.div`relative select-none font-semibold`;

const Item = tw.p`px-4 py-3 pl-10 cursor-pointer text-list-select-selector-label text-base`;

const ActiveItemIcon = tw.span`absolute inset-y-0 left-3 flex items-center text-list-select-selector-icon`;

const ListSelect = ({
  name,
  isvalue,
  selected,
  options,
  onChange,
  width,
}: IListSelect) => {
  return (
    <Listbox value={selected} onChange={(value) => onChange(name, value)}>
      <Container width={width} isvalue={isvalue}>
        <Listbox.Button as={Selector}>
          <Label>{selected}</Label>
          <div>
            <Icon.Selector />
          </div>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options as={Options}>
            {options.map((option, index) => (
              <Listbox.Option key={index} value={option.value} as={Option}>
                {({ active, selected }) => (
                  <Item className={`${active && "bg-gray-100/40"}`}>
                    {selected && (
                      <ActiveItemIcon>
                        <Icon.Check />
                      </ActiveItemIcon>
                    )}

                    {option.label}
                  </Item>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Container>
    </Listbox>
  );
};

export default ListSelect;
