import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

const Dropdown = ({ trigger, content, itemsStyle }: IDropdown) => {
  return (
    <Menu>
      <Menu.Button>{trigger}</Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={itemsStyle}>{content}</Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
