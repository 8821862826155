import { PropsWithChildren } from "react";
import { ToastContentProps } from "react-toastify";

const Notify = ({
  // closeToast,
  // toastProps,
  children,
}: Partial<ToastContentProps> & PropsWithChildren) => {
  return <div>{children}</div>;
};

export default Notify;
