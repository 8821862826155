import tw from "tailwind-styled-components";
import Placeholder from "@c/placeholder";
import formatInt from "@u/format-int";
import { useLoan } from "@/hooks/use-loan";
import DeleteOffer from "@b/modals/delete-offer";
import storage from "@/lib/storage";

const Container = tw.div`flex flex-1 flex-col gap-y-6 bg-white p-6 rounded-xl`;

const Heading = tw.p`text-[18.5px] font-bold text-pages-loan-detail-heading`;

const Content = tw.div`flex flex-col pt-0 gap-y-12 overflow-scroll no-scrollbar h-full`;

const ItemGroup = tw.div`flex flex-col gap-y-3 w-full`;

const ItemContent = tw.div`flex flex-row items-center`;

const Item = tw.div`flex flex-col gap-y-3 w-1/5`;

const Label = tw.p`uppercase text-sm font-bold text-pages-loan-detail-label`;

const Title = tw.p`w-2/6 text-pages-loan-detail-title`;

const Value = tw.p`flex gap-x-2 text-pages-loan-detail-value break-words capitalize items-center`;

const ItemLabel = tw.div`italic text-red-500 text-sm`;

const ItemSpan = tw.p`italic text-pages-loan-detail-label text-sm`;

const Assets = ({ id }: IOffer) => {
  const isAdmin = storage.utilities.isAdmin();
  const { isLoading, isSuccess, data } = useLoan(id, isAdmin);

  const response = isSuccess && JSON.parse(data?.data);

  return (
    <Container>
      <Heading>Offers</Heading>

      <Content>
        {isLoading && (
          <ItemGroup>
            <Placeholder align="left" />

            <ItemContent>
              <Item>
                <Placeholder align="left" />
                <Placeholder align="left" />
              </Item>

              <Item>
                <Placeholder align="left" />
                <Placeholder align="left" />
              </Item>

              <Item>
                <Placeholder align="left" />
                <Placeholder align="left" />
              </Item>

              <Item>
                <Placeholder align="left" />
                <Placeholder align="left" />
              </Item>

              <Item>
                <Placeholder align="left" />
                <Placeholder align="left" />
              </Item>
            </ItemContent>
          </ItemGroup>
        )}

        {isSuccess &&
          response.offers.map((offer: any, index: any) => {
            return (
              <ItemGroup key={index}>
                <Label>{offer.partner.name}</Label>

                <ItemContent>
                  <Item>
                    <Title>Approved Amount:</Title>
                    <Value>
                      {formatInt(offer.approvedAmount, true) || "---"}
                    </Value>
                  </Item>
                  <Item>
                    <Title>Interest Rate:</Title>
                    <Value>
                      {offer.interest ? `${offer.interest}%` : "---"}
                    </Value>
                  </Item>
                  <Item>
                    <Title>Loan Duration:</Title>
                    <Value>
                      {offer.tenure ? `${offer.tenure} Months` : "---"}
                    </Value>
                  </Item>
                  <Item>
                    <Title>Monthly Repayment:</Title>
                    <Value>
                      {formatInt(offer.monthlyRepayment, true) || "---"}
                    </Value>
                  </Item>
                  <Item>
                    <Title>‎ Equity:</Title>
                    <Value>{formatInt(offer.equity, true) || "---"}</Value>
                  </Item>

                  <Item>
                    <Title>‎ Status:</Title>
                    <Value>{offer.status || "---"}</Value>
                  </Item>
                  <DeleteOffer id={offer.id} loanId={id} />
                </ItemContent>
                {offer.reasonForDeclination && (
                  <ItemLabel>
                    <ItemSpan>Reason for rejection</ItemSpan>({offer.declinedBy}
                    ):{" "}
                    {offer.reasonForDeclination === "Other"
                      ? offer.reasonForDeclinationDescription
                      : offer.reasonForDeclination}
                  </ItemLabel>
                )}
              </ItemGroup>
            );
          })}

        {isSuccess && response.offers.length === 0 && (
          <ItemGroup>
            <Label>---</Label>

            <ItemContent>
              <Item>
                <Title>Approved Amount:</Title>
                <Value>---</Value>
              </Item>

              <Item>
                <Title>Interest Rate:</Title>
                <Value>---</Value>
              </Item>

              <Item>
                <Title>Loan Duration:</Title>
                <Value>---</Value>
              </Item>

              <Item>
                <Title>Monthly Repayment:</Title>
                <Value>---</Value>
              </Item>

              <Item>
                <Title>Equity:</Title>
                <Value>---</Value>
              </Item>
            </ItemContent>
          </ItemGroup>
        )}
      </Content>
    </Container>
  );
};

export default Assets;
