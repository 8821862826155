import tw from "tailwind-styled-components";
import Placeholder from "@c/placeholder";
import formatInt from "@u/format-int";
import { useLoan } from "@/hooks/use-loan";
import storage from "@/lib/storage";
import formatDate from "@/utils/format-date";
import { isIsoDate, LoanStatusColor } from "@/utils/commonFunctions";

const Container = tw.div`flex flex-col gap-y-6 bg-white p-6 rounded-xl w-2/6`;

const Heading = tw.p`text-[18.5px] font-bold text-pages-loan-detail-heading`;

const Content = tw.div`flex flex-col flex-wrap pt-0 gap-y-12 overflow-scroll h-full w-full justify-between no-scrollbar`;

const ItemGroup = tw.div`flex flex-col gap-y-3 w-full`;

const Item = tw.div`flex flex-row gap-x-3 items-center`;

const Label = tw.p`uppercase text-sm font-bold text-pages-loan-detail-label`;

const Title = tw.p`w-3/6 text-pages-loan-detail-title capitalize`;

const Value = tw.div`w-3/6 text-pages-loan-detail-value break-words capitalize`;

const LoanContainer = tw.div`flex flex-col gap-y-3 bg-pages-loan-detail-loan-container-scene rounded-lg mt-5 p-5`;

const TempValue = tw.p`w-3/6 text-pages-loan-detail-value break-words`;

const Tag = tw.div`
  text-white
  text-base
  py-1
  px-2
  rounded-md
  inline-flex	
  place-self-start
  ${({ color }: LoanTag) => `${color}`}
`;

const CustomerProfile = ({ id }: ICustomerProfile) => {
  const isAdmin = storage.utilities.isAdmin();
  const { isLoading, isSuccess, isError, data } = useLoan(id, isAdmin);

  const response = isSuccess && JSON.parse(data?.data);

  const personalInformation =
    isSuccess &&
    response.customer?.profile?.filter(
      (profile: any) =>
        profile.type !== "file" &&
        !profile?.value?.startsWith("https://storage.googleapis.com")
    );

  const loanValue = isSuccess && response.loanValue;

  const loanStatus = isSuccess && response.status;

  const loanData: SingleLoan = isSuccess && response;

  const getItemState = (value: string | null, theme?: string) => {
    if (isLoading) {
      return <Placeholder color={theme} align="left" />;
    } else if (!isLoading && isSuccess) {
      if (value && isIsoDate(value)) {
        return formatDate(value);
      }
      return value || "---";
    } else if (isError) {
      return <Value>---</Value>;
    }
  };

  function camelToWords(key: string) {
    var result = key ? key.replace(/([A-Z])/g, " $1") : "";
    return result.split(" ").join(" ").toLowerCase();
  }

  return (
    <Container>
      {loanStatus && (
        <Tag color={LoanStatusColor(loanStatus)}>{loanStatus}</Tag>
      )}
      <Heading>Customer Profile</Heading>

      <Content>
        <ItemGroup>
          <Label>Personal Information</Label>

          {isSuccess &&
            personalInformation?.map((info: any, index: string) => (
              <Item key={index}>
                <Title>{camelToWords(info.name)}:</Title>
                {info.name === "salary" && (
                  <Value>{getItemState(formatInt(info.value, true))}</Value>
                )}
                {info.name === "email" && (
                  <TempValue>{getItemState(info.value)}</TempValue>
                )}
                {info.name === "telephone" && (
                  <Value>+{getItemState(info.value)}</Value>
                )}
                {info.name !== "salary" &&
                  info.name !== "email" &&
                  info.name !== "telephone" && (
                    <Value>{getItemState(info.value)}</Value>
                  )}
              </Item>
            ))}
        </ItemGroup>

        <ItemGroup>
          <LoanContainer>
            <Label>Loan Details</Label>

            <Item>
              <Title>Loan Requested:</Title>
              <Value>
                {getItemState(formatInt(loanValue, true), "bg-white")}
              </Value>
            </Item>
            <Item>
              <Title>Loan Created Date:</Title>
              <Value>
                {getItemState(formatDate(loanData?.lastSeen), "bg-white")}
              </Value>
            </Item>
            <Item>
              <Title>Last Updated Date:</Title>
              <Value>
                {getItemState(formatDate(loanData?.lastSeen), "bg-white")}
              </Value>
            </Item>
          </LoanContainer>
        </ItemGroup>
      </Content>
    </Container>
  );
};

export default CustomerProfile;
