import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";

const partnerDetail = (partnerId: string | undefined) => {
  return client.get(`/origination/partners/${partnerId}`);
};

export const usePartnerDetail = (
  partnerId: string | undefined,
  enabled: boolean
) => {
  return useQuery(
    ["partnerDetail", partnerId],
    () => partnerDetail(partnerId),
    {
      enabled,
      retry: false,
      refetchInterval: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};
