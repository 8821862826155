import { useQuery } from "@tanstack/react-query";
import client from "@/lib/axios-client";

const products = (country: string) => {
  const params = new URLSearchParams({
    country,
    active: "true",
    public: "true",
  });

  return client.get("/origination/product", { params });
};

export const useProducts = (country: string) => {
  return useQuery(["product", country], () => products(country), {
    retry: false,
    refetchInterval: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
