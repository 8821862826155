import { useState } from "react";
import tw from "tailwind-styled-components";
import Icon from "@c/icons";

const Container = tw.div`flex mx-auto py-6`;

const BaseButton = tw.button`flex justify-center items-center h-10 w-10 border text-sm border-pagination-button-border text-pagination-button-label hover:bg-pagination-button-hover-scene duration-150 disabled:cursor-not-allowed`;

const Previous = tw(
  BaseButton
)`rounded-l-md border-r-0 hover:bg-pagination-button-action-hover-scene hover:text-white`;

const Next = tw(
  BaseButton
)`rounded-r-md hover:bg-pagination-button-action-hover-scene hover:text-white`;

const Button = tw(BaseButton)`
  ${(props: IPaginationButton) =>
    props.$isActive
      ? "text-pagination-button-label bg-pagination-button-active-scene hover:bg-pagination-button-active-scene/90"
      : "bg-transparent text-pagination-button-label"}
      
border-r-0`;

const Paginate = (
  size: number,
  current: number,
  goto: (page: number) => void
) => {
  const delta = 2;
  const range = [];

  for (
    let i = Math.max(2, current - delta);
    i <= Math.min(size - 1, current + delta);
    i += 1
  ) {
    range.push(i);
  }

  if (current - delta > 2) {
    range.unshift("...");
  }

  if (current + delta < size - 1) {
    range.push("...");
  }

  range.unshift(1);
  if (size !== 1) range.push(size);

  return range.map((i, index) => {
    const isCurrentPage = current === i;

    return !isNaN(i as number) ? (
      <Button
        value={i}
        key={index}
        onClick={() => goto(i as number)}
        $isActive={isCurrentPage}
        disabled={isCurrentPage}
      >
        {i}
      </Button>
    ) : (
      <Button key={index}>{i}</Button>
    );
  });
};

const Pagination = ({ size, current, onPageChange }: IPagination) => {
  const [pageSize] = useState<number>(size);
  const [currentPage, setCurrentPage] = useState<number>(current);

  const previous = () => {
    if (currentPage !== 1) {
      setCurrentPage((current) => --current);
      onPageChange(--current);
    }
  };

  const next = () => {
    if (currentPage < pageSize) {
      setCurrentPage((current) => ++current);
      onPageChange(++current);
    }
  };

  const first = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      onPageChange(1);
    }
  };

  const last = () => {
    if (currentPage < pageSize) {
      setCurrentPage(pageSize);
      onPageChange(pageSize);
    }
  };

  const goto = (page: number) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  return (
    <Container>
      <Previous onClick={() => first()}>
        <Icon.PaginateFirst />
      </Previous>
      <Button onClick={() => previous()}>
        <Icon.PaginateBackward />
      </Button>
      {Paginate(pageSize, currentPage, goto)}
      <Button onClick={() => next()}>
        <Icon.PaginateForward />
      </Button>
      <Next onClick={() => last()}>
        <Icon.PaginateLast />
      </Next>
    </Container>
  );
};

export default Pagination;
