import tw from "tailwind-styled-components";

const Container = tw.div`flex justify-center items-center h-12 w-12 bg-avatar-scene rounded-full`;

const Label = tw.div`text-xl text-avatar-label`;

const Avatar = ({ label }: IAvatar) => {
  return (
    <Container>
      <Label>{label}</Label>
    </Container>
  );
};

export default Avatar;
