import { useMutation } from "@tanstack/react-query";
import client from "@/lib/axios-client";

const signIn = (data: ISignIn) => {
  return client.post("/auth/login", data);
};

export const useSignIn = () => {
  return useMutation(signIn);
};
