import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, BackButton } from "@p/loans/details/styles/[ID]";
import CustomerProfile from "@b/loan/customer-profile";
import Documents from "@b/loan/documents";
import Offer from "@b/loan/offer";
import Assets from "@b/loan/assets";

const LoanByID = () => {
  const navigate = useNavigate()
  const { id } = useParams();

  useEffect(() => {
    document.title = `ACM | ${id} Application`;
  });

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)}>Go Back</BackButton>
      <Row>
        <CustomerProfile id={id} />
        <Col>
          <Documents id={id} />
          <Offer id={id} />
        </Col>
      </Row>
      <Assets id={id} />
    </Container>
  );
};

export default LoanByID;
