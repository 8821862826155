const makeId = (n: number): string => {
  let id: string = "";
  const seed = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const seedLength = seed.length;

  for (var i = 0; i < n; i++) {
    id += seed.charAt(Math.floor(Math.random() * seedLength));
  }

  return id;
};

export default makeId;
