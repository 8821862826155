import tw from "tailwind-styled-components";

export const Container = tw.div`flex flex-col gap-y-10 justify-center items-center w-full h-full`;

export const Content = tw.div`w-[420px] h-auto p-8 bg-white rounded-lg`;

export const Logo = tw.img`w-14 h-14`;

export const Title = tw.p`text-center text-2xl font-semibold`;

export const Description = tw.p`text-gray-400 text-center`;

export const Form = tw.form`mt-0`;

export const Inputs = tw.div`flex flex-col gap-y-6 mb-6`;
