import { useEffect, useRef } from "react";
import tw from "tailwind-styled-components";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "@c/modal";
import Notify from "@c/notify";
import Button from "@c/button";
import { useAddAdditionalDocument } from "@/hooks/use-loan";
import storage from "@l/storage";
import RegularInput from "@b/inputs/regular";
import Icon from "@c/icons";

const Container = tw.div`flex flex-col gap-y-5`;

const Form = tw.form`flex flex-col gap-y-5`;

const FormItem = tw.span`flex flex-col gap-y-2`;

const ScrollView = tw.div`overflow-auto max-h-96`;

const Label = tw.p`text-modal-make-offer-label`;

const AddAdditionalDocumentsss = () => {
  const country = storage.getCountry();
  const modalRef = useRef<ModalRef>();

  const schema = yup
    .object({
      name: yup.string().required(),
      description: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddAdditionalDocuments>({
    resolver: yupResolver(schema),
  });

  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, isSuccess, error } =
    useAddAdditionalDocument();

  const handleSignIn = (data: AddAdditionalDocuments) => {
    mutate({ ...data, country });
  };

  useEffect(() => {
    isError &&
      error instanceof AxiosError &&
      Notify.error(JSON.parse(error.response?.data).message);
  }, [isError, error]);

  useEffect(() => {
    if (isSuccess) {
      Notify.success("Documents has been Added successfully .");
      queryClient.invalidateQueries(["AdditionalDocuments", country]);
      modalRef.current?.close();
    }
  }, [isSuccess, country, queryClient]);

  return (
    <Modal
      ref={modalRef}
      header="Add Additional Documents"
      trigger={<Icon.Add />}
      width="w-[400px]"
      content={
        <Container>
          <Form onSubmit={handleSubmit(handleSignIn)} autoComplete="off">
            <ScrollView>
              <FormItem>
                <Label>Name</Label>
                <RegularInput
                  type="text"
                  label="name"
                  {...register("name")}
                  error={errors.name}
                />
              </FormItem>
              <FormItem>
                <Label>Description</Label>
                <RegularInput
                  type="text"
                  label="description"
                  {...register("description")}
                  error={errors.description}
                />
              </FormItem>
            </ScrollView>

            <Button isLoading={isLoading} variant="secondary" width="w-full">
              Add Document
            </Button>
          </Form>
        </Container>
      }
    />
  );
};

export default AddAdditionalDocumentsss;
