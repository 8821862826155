import { useState, useEffect } from "react";

const Copy = ({ text, copied, children }: ICopy) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;

    timerId = setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    return () => clearTimeout(timerId);
  }, [isCopied]);

  const onCopy = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(text);
  };

  return isCopied ? <>{copied}</> : <div onClick={onCopy}>{children}</div>;
};

export default Copy;
