import tw from "tailwind-styled-components";
import Placeholder from "@c/placeholder";
import MakeOffer from "@b/modals/make-offer";
import formatInt from "@u/format-int";
import storage from "@l/storage";
import { useDetailedInspection, useGetPPI } from "@h/use-loan";
import Icon from "@c/icons";
import { useLoan } from "@/hooks/use-loan";
import RejectOffer from "@b/modals/reject-offer";
import RequestAdditionalDocuments from "@/components/blocks/modals/requestAdditionalDocuments";

const Container = tw.div`flex flex-col gap-y-6 bg-white p-6 rounded-xl`;

const Heading = tw.p`text-[18.5px] font-bold text-pages-loan-detail-heading`;

const Content = tw.div`flex flex-wrap pt-0 gap-y-12 overflow-scroll h-full w-full justify-between no-scrollbar`;

const ItemGroup = tw.div`flex flex-col gap-y-6 w-[50%] justify-center`;

const ButtonGroup = tw.div`mx-auto flex flex-row gap-x-5`;

const Item = tw.div`flex flex-col gap-y-1`;

const Title = tw.p`w-3/6 text-pages-loan-detail-title`;

const Value = tw.p`flex gap-x-2 w-3/6 font-bold text-pages-loan-detail-value break-words capitalize items-center`;

const Link = tw.a`font-normal text-sm text-pages-loan-detail-title break-words capitalize underline hover:no-underline cursor-pointer`;

const ImageContainer = tw.div`w-5/6 mx-auto`;

const Image = tw.img`rounded-lg h-[400px]`;

const Assets = ({ id }: Keyable) => {
  const isAdmin = storage.utilities.isAdmin();
  const { isLoading, isSuccess, isError, data } = useLoan(id, isAdmin);

  const { car, loanValue } = isSuccess && JSON.parse(data?.data);

  const { isFetched: isDIFetched, data: dIData } = useDetailedInspection(
    car?.id,
    !!car?.id
  );

  const { isFetched: isPPIFetched, data: ppiData } = useGetPPI(
    car?.id,
    !!car?.id
  );

  const ppiResponse = isPPIFetched && JSON.parse(ppiData?.data);

  const { partner } = storage?.getUser() || {};

  const { id: partnerId } = partner || {};

  const getItemState = (value: string | null, theme?: string) => {
    if (isLoading) {
      return <Placeholder color={theme} align="left" />;
    } else if (!isLoading && isSuccess) {
      return value || "---";
    } else if (isError) {
      return <Value>---</Value>;
    }
  };

  return (
    <Container>
      <Heading>Assets</Heading>

      {car ? (
        <Content>
          <ItemGroup>
            <Item>
              <Title>Car ID:</Title>
              <Value>{getItemState(car.id)}</Value>
            </Item>

            <Item>
              <Title>Make & Model:</Title>
              <Value>
                {getItemState(`${car.model.make.name} ${car.model.name}`)}
              </Value>
            </Item>

            <Item>
              <Title>VIN:</Title>
              <Value>{getItemState(car.vin)}</Value>
            </Item>

            <Item>
              <Title>Inspection Score:</Title>
              <Value>
                <Icon.Star />
                {isDIFetched
                  ? parseFloat(JSON.parse(dIData?.data || {}).score).toFixed(
                      1
                    ) || "---"
                  : "---"}
              </Value>
            </Item>

            <Item>
              <Title>Pre-Purchase Inspection:</Title>
              <Value>
                {ppiResponse && ppiResponse.result?.[0]?.pdfReport ? (
                  <Link
                    href={ppiResponse.result?.[0]?.pdfReport}
                    target="_blank"
                  >
                    View Inspection Result
                  </Link>
                ) : (
                  "---"
                )}
              </Value>
            </Item>

            <Item>
              <Title>Vehicle Price:</Title>
              <Value>
                {getItemState(formatInt(Number(car.marketplacePrice), true))}
              </Value>
            </Item>
          </ItemGroup>
          <ItemGroup>
            <ImageContainer>
              {car.imageUrl && <Image alt={car.carName} src={car.imageUrl} />}
            </ImageContainer>
          </ItemGroup>

          {!isAdmin && (
            <ButtonGroup>
              <MakeOffer
                loanid={id}
                partnerid={partnerId}
                loanrequested={loanValue}
              />
              <RejectOffer loanid={id} partnerid={partnerId} />
              <RequestAdditionalDocuments loanid={id} partnerid={partnerId} />
            </ButtonGroup>
          )}
        </Content>
      ) : (
        <>
          <Title>Car not selected</Title>

          {!isAdmin && (
            <ButtonGroup>
              <MakeOffer
                loanid={id}
                partnerid={partnerId}
                loanrequested={loanValue}
              />

              <RejectOffer loanid={id} partnerid={partnerId} />
              <RequestAdditionalDocuments loanid={id} partnerid={partnerId} />
            </ButtonGroup>
          )}
        </>
      )}
    </Container>
  );
};

export default Assets;
