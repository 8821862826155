import { toast, ToastOptions } from "react-toastify";
import { default as CustomNotification } from "@b/notify";
import makeId from "@u/make-id";

const config: ToastOptions = {
  toastId: makeId(5),
  position: "top-center",
  icon: false,
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
  progress: undefined,
};

const Notify: INotify = {
  success: (message: string) => {
    toast.success(<CustomNotification children={message} />, config);
  },
  error: (message: string) => {
    toast.error(<CustomNotification children={message} />, config);
  },
};

export default Notify;
