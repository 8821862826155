import { useEffect, useCallback, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import shallow from "zustand/shallow";
import {
  Container,
  Content,
  // Title,
  // Description,
  Form,
  Inputs,
  Logo,
} from "@p/auth/styles/sign-in";
import RegularInput from "@b/inputs/regular";
import Button from "@c/button";
import Notify from "@c/notify";
import asideState from "@s/aside";
import { useSignIn } from "@h/use-authentication";
import { usePartnerDetail } from "@h/use-partner";
import storage from "@l/storage";
import makeId from "@u/make-id";
import authState from "../../states/auth";
import adminRoles from "@cn/admin-roles.json";
import financePartners from "@cn/finance-partners.json";

const schema = yup
  .object({
    email: yup
      .string()
      .email("Kindly provide a valid email")
      .required("Email field cannot be left blank"),
    password: yup.string().required("There can't be a blank password field"),
  })
  .required();

const SignIn = () => {
  const [fetchPartner, setFetchPartner] = useState<IFetchPartnerState>({
    enabled: false,
    partnerId: undefined,
  });

  const [setVisibility, setCollapsibility] = asideState(
    (state) => [state.setVisibility, state.setCollapsibility],
    shallow
  );

  useEffect(() => {
    setVisibility(false);
    setCollapsibility(false);
  }, [setCollapsibility, setVisibility]);

  useEffect(() => {
    document.title = "ACM | Sign In";
  }, []);

  const signInParseData = useRef<Keyable>({});
  const navigate = useNavigate();
  const location = useLocation() as IUseLocation;
  const redirectPath = location.state?.path || "/";
  const setLogin = authState((state: IAuthState) => state.setLogin);

  const {
    mutate: signInMutate,
    isLoading: isSignInLoading,
    isError: isSignInError,
    isSuccess: isSignInSuccess,
    error: signInError,
    data: signInData,
  } = useSignIn();
  const {
    isFetching: isPartnerFetching,
    isSuccess: isPartnerSuccess,
    isError: isPartnerError,
    error: partnerError,
    data: partnerData,
  } = usePartnerDetail(fetchPartner.partnerId, fetchPartner.enabled);

  const getPartnerDetail = useCallback((data: AxiosResponse) => {
    signInParseData.current = JSON.parse(data.data);

    const roles = signInParseData.current.authority.roles;
    const partnerId = signInParseData.current.user.partnerId;
    const token = signInParseData.current.token;
    storage.setToken(token);

    const allowedRoles = [...adminRoles, ...financePartners];
    const isAdminRole = adminRoles.some((role) => roles.includes(role));

    if (allowedRoles.some((role) => roles.includes(role))) {
      if (isAdminRole && partnerId) {
        setFetchPartner((prevState) => ({
          ...prevState,
          partnerId,
          enabled: true,
        }));
      } else if (isAdminRole && !partnerId) {
        setFetchPartner((prevState) => ({
          ...prevState,
          partnerId,
          enabled: true,
        }));
      } else if (!isAdminRole && partnerId) {
        setFetchPartner((prevState) => ({
          ...prevState,
          partnerId,
          enabled: true,
        }));
      } else {
        storage.clearToken();
        Notify.error("You don't have an assigned partner.");
        return;
      }
    } else {
      storage.clearToken();
      Notify.error("You don't have the required role.");
      return;
    }
  }, []);

  useEffect(() => {
    isSignInSuccess && getPartnerDetail(signInData);
  }, [signInData, isSignInSuccess, getPartnerDetail]);

  const processSignIn = useCallback(
    (data: AxiosResponse) => {
      setFetchPartner((prevState) => ({
        ...prevState,
        enabled: false,
      }));

      const partnerParsedData = JSON.parse(data.data);

      const user = signInParseData.current.user;
      user.partner = partnerParsedData;

      if(!user.partner?.active){
        storage.clearToken();
        Notify.error("You do not have an active partner");
        return;
      }

      storage.setUser(user);

      const roles = signInParseData.current.authority.roles;
      storage.setRoles(roles);

      const permissions = signInParseData.current.authority.permissions;
      storage.setPermissions(permissions);

      const country = signInParseData.current.user.country;
      storage.setCountry(country);

      setLogin(true);
      navigate(redirectPath, { replace: true });
    },
    [navigate, redirectPath, setLogin]
  );

  useEffect(() => {
    if (isPartnerError) {
      storage.clearToken();
      setFetchPartner((prevState) => ({
        ...prevState,
        enabled: false,
      }));
    }
  }, [isPartnerError]);

  useEffect(() => {
    isPartnerSuccess && processSignIn(partnerData);
  }, [processSignIn, isPartnerSuccess, partnerData]);

  useEffect(() => {
    const user = storage.getUser();
    user && navigate(redirectPath, { replace: true });
  });

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm<ISignInInput>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    isSignInError &&
      signInError instanceof AxiosError &&
      Notify.error(JSON.parse(signInError.response?.data).message);
    isSignInError &&
      signInError instanceof AxiosError &&
      resetField("password");
  }, [isSignInError, resetField, signInError]);

  useEffect(() => {
    isPartnerError &&
      partnerError instanceof AxiosError &&
      Notify.error(JSON.parse(partnerError.response?.data).message);
  }, [isPartnerError, partnerError]);

  const handleSignIn = (data: ISignInInput) => {
    signInMutate({ ...data });
  };

  return (
    <Container>
      <Logo alt="Autochek's Logo" src="/logo.png" />

      <Content>
        {/* <Title>Welcome Back</Title>
        <Description>
          Enter your credentials to access your account.
        </Description> */}

        <Form onSubmit={handleSubmit(handleSignIn)} autoComplete="off">
          <Inputs>
            <RegularInput
              label="Email"
              type="email"
              placeholder="Email Example: john@acne-design.co"
              {...register("email")}
              error={errors.email}
            />

            <RegularInput
              label="Password"
              type="password"
              placeholder={`Password Example: ${makeId(7)}`}
              {...register("password")}
              error={errors.password}
            />
          </Inputs>

          <Button
            isLoading={isSignInLoading || isPartnerFetching}
            variant="primary"
            width="w-full"
          >
            Sign In
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default SignIn;
