import tw from "tailwind-styled-components";

const Layout = tw``;

const Container = tw.div`flex w-full items-stretch`;

const Aside = tw.div`fixed h-screen bg-layout-aside-scene w-72 overflow-hidden`;

const Main = tw.div`
${(props: ILayoutMain) =>
  props.$isAsideVisible ? "ml-72 duration-300" : "ml-0 duration-500"}

flex-1 h-screen p-12 overflow-scroll no-scrollbar`;

Layout.Container = Container;
Layout.Aside = Aside;
Layout.Main = Main;

export default Layout;
