import storage from "@l/storage";

const getSymbol = () => {
  let symbol;
  const country = storage.getCountry();

  switch (country) {
    case "NG":
      symbol = "₦ ";
      break;
    case "GH":
      symbol = "GH₵ ";
      break;
    case "KE":
      symbol = "KSh ";
      break;
    case "CI":
      symbol = "CFA ";
      break;
    case "UG":
      symbol = "USh ";
      break;
    default:
      symbol = "";
      break;
  }

  return symbol;
};

const formatInt = (value: number, withSymbol?: boolean): string => {
  if (value !== undefined && value !== 0) {
    const stringValue = value.toString();
    const amountChunk = Number(stringValue.split(".")[0]);
    const decimalChunk = stringValue.split(".")[1]
      ? `.${stringValue.split(".")[1]}`
      : "";

    return `${withSymbol && getSymbol()}${amountChunk.toLocaleString(
      "en-US"
    )}${decimalChunk}`;
  }

  return "---";
};

export default formatInt;
