import tw from "tailwind-styled-components";
import shallow from "zustand/shallow";
import Icon from "@/components/icons";
import asideState from "@s/aside";
import { controlMounted, controlUnmounted } from "@h/use-mount";

const Container = tw.div`w-8 h-20 left-72 bg-layout-aside-control-scene flex justify-center items-center absolute top-0 bottom-0 my-auto rounded-r-lg cursor-pointer`;

const Control = ({ onClick }: IAsideControl) => {
  const [isVisible, isCollapsible] = asideState(
    (state) => [state.isVisible, state.isCollapsible],
    shallow
  );

  return (
    <Container
      style={isVisible ? controlMounted : controlUnmounted(isCollapsible)}
      onClick={onClick}
    >
      <Icon.AsideControl $isAsideVisible={isVisible} />
    </Container>
  );
};

export default Control;
