import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import tw from "tailwind-styled-components";
import Icon from "@c/icons";

const Container = tw.div`flex flex-row justify-between items-center bg-layout-aside-menu-item-scene hover:bg-layout-aside-menu-item-hover-scene border-b last:border-b-0 border-b-layout-aside-menu-item-border px-4 py-5 cursor-pointer`;

const SubContainer = tw.div`overflow-hidden`;

const SubLabel = tw.div`text-inherit text-base font-regular leading-6 whitespace-nowrap bg-layout-aside-menu-sub-item-scene hover:bg-layout-aside-menu-sub-item-hover-scene cursor-pointer px-7 py-5 tracking-wide`;

const Label = tw.p`text-inherit text-base font-regular leading-6 whitespace-nowrap tracking-wide`;

const Item = ({ $to, $subItem, children }: IAsideItem) => {
  let content;
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);
  const [isActiveRoute, setIsActiveRoute] = useState(false);

  const activeRoute = $to
    .split("/")
    .slice(1)
    .some((singlePath: string) =>
      location.pathname.split("/").slice(1).includes(singlePath)
    );

  useEffect(() => {
    setIsActiveRoute(activeRoute);
    activeRoute ? setIsVisible(true) : setIsVisible(false);
  }, [$to, activeRoute, location.pathname]);

  const toggleSubMenuItem = () => setIsVisible((isVisible) => !isVisible);

  if ($subItem === undefined || $subItem.length === 0) {
    content = (
      <Container>
        <NavLink
          className={({ isActive }) =>
            isActive ? "text-layout-aside-menu-item-active" : "text-white"
          }
          to={$to}
        >
          <Label>{children}</Label>
        </NavLink>
      </Container>
    );
  } else {
    content = (
      <>
        <Container onClick={toggleSubMenuItem}>
          <div
            className={`flex flex-row justify-between w-full ${
              isActiveRoute && isVisible
                ? "text-layout-aside-menu-item-active"
                : "text-white"
            }`}
          >
            <Label>{children}</Label>
            <Icon.AsideItemToggle $isVisible={isVisible} />
          </div>
        </Container>

        {isVisible && (
          <SubContainer>
            {$subItem.map((item, index) => (
              <NavLink
                className={({ isActive }) =>
                  isActive ? "text-layout-aside-menu-item-active" : "text-white"
                }
                to={item.path}
                key={index}
              >
                <SubLabel>{item.title}</SubLabel>
              </NavLink>
            ))}
          </SubContainer>
        )}
      </>
    );
  }

  return content;
};

export default Item;
