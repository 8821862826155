import tw from "tailwind-styled-components";

export const Container = tw.div`flex flex-col h-full`;

export const Group = tw.div`mb-10`;

export const Header = tw.div`flex justify-between items-center gap-x-5`;

export const DateGroup = tw.div`flex flex-row gap-x-5 mt-5 items-center`;

export const DateGroupTitle = tw.p`py-3 text-md font-semibold bg-table-content-th-scene text-table-content-th-label sticky top-0`;
