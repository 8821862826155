import _ from "lodash";

const extractItems = (data: Array<Keyable>, items: string[]) => {
  let result: Array<Keyable> = [];

  _.find(data, (obj) => {
    _.forOwn(items, (value, _key) => {
      if (obj.name === value || obj.id === value) {
        result.push(obj);
      }
    });
  });

  return result;
};

export const getItem = (data: Array<Keyable>, item: string) => {
  return _.find(data, (obj) => {
    if (obj.name === item) {
      return obj;
    }
  });
};

export default extractItems;
