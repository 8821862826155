import tw from "tailwind-styled-components";

const Container = tw.div`flex justify-center items-center h-full w-full`;

const Content = tw.div`flex flex-col text-center items-center gap-y-1`;

const IconContainer = tw.div`mb-3`;

const Label = tw.p`text-[17px] text-query-empty-label`;

const QueryEmpty = () => {
  return (
    <Container>
      <Content>
        <IconContainer>
          <svg
            width="172px"
            height="88px"
            viewBox="0 0 172 88"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Group 3</title>
            <g
              id="Symbols"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="Group-3" transform="translate(0.175781, 1.000000)">
                <path
                  d="M171.824219,7 C171.824219,10.8659933 168.690212,14 164.824219,14 L124.824219,14 C128.690212,14 131.824219,17.1340067 131.824219,21 C131.824219,24.8659933 128.690212,28 124.824219,28 L146.824219,28 C150.690212,28 153.824219,31.1340067 153.824219,35 C153.824219,38.865993 150.690212,42 146.824219,42 L136.650299,42 C131.775786,42 127.824219,45.134007 127.824219,49 C127.824219,51.5773289 129.824219,53.9106619 133.824219,56 C137.690212,56 140.824219,59.134007 140.824219,63 C140.824219,66.865993 137.690212,70 133.824219,70 L50.8242188,70 C46.9582255,70 43.8242188,66.865993 43.8242188,63 C43.8242188,59.134007 46.9582255,56 50.8242188,56 L11.8242188,56 C7.95822549,56 4.82421875,52.865993 4.82421875,49 C4.82421875,45.134007 7.95822549,42 11.8242188,42 L51.8242188,42 C55.690212,42 58.8242188,38.865993 58.8242188,35 C58.8242188,31.1340067 55.690212,28 51.8242188,28 L26.8242188,28 C22.9582255,28 19.8242188,24.8659933 19.8242188,21 C19.8242188,17.1340067 22.9582255,14 26.8242188,14 L66.8242188,14 C62.9582257,14 59.8242188,10.8659933 59.8242188,7 C59.8242188,3.13400674 62.9582257,0 66.8242188,0 L164.824219,0 C168.690212,0 171.824219,3.13400674 171.824219,7 Z M171.824219,35 C171.824219,38.865993 168.690212,42 164.824219,42 C160.958226,42 157.824219,38.865993 157.824219,35 C157.824219,31.1340067 160.958226,28 164.824219,28 C168.690212,28 171.824219,31.1340067 171.824219,35 Z"
                  id="Background"
                  fill="#F6F7FB"
                ></path>
                <g id="Group" transform="translate(0.000000, 1.000000)">
                  <g id="Search" transform="translate(42.824219, 0.000000)">
                    <path
                      d="M33.5,67 C14.9984608,67 0,52.0015392 0,33.5 C0,14.9984608 14.9984608,0 33.5,0 C52.0015392,0 67,14.9984608 67,33.5 C67,52.0015392 52.0015392,67 33.5,67 Z"
                      id="Oval"
                      stroke="#30345E"
                      strokeWidth="2.5"
                      fill="#F6F7FB"
                      strokeDasharray="0,0"
                    ></path>
                    <path
                      d="M28.1320801,59.4938965 C29.8908139,59.818529 31.6801207,59.9872302 33.5,60 C48.1355457,60 60,48.1355457 60,33.5 C60,18.8644543 48.1355457,7 33.5,7 C29.7403617,7 26.1635861,7.78292871 22.9238205,9.19463825 C17.2938414,11.6478698 12.6815836,15.9999299 9.89598751,21.4418812 C8.04450583,25.0589428 7,29.1574931 7,33.5 C7,37.4400034 7.85985088,41.1791797 9.40213871,44.5401115 C10.5032233,46.9395795 11.9521427,49.1462562 13.6843386,51.0955887"
                      id="Oval"
                      stroke="#30345E"
                      strokeWidth="2.5"
                      fill="#FFFFFF"
                      strokeLinecap="round"
                      strokeDasharray="0,0"
                    ></path>
                    <path
                      d="M28.1320801,59.4938965 C29.8908139,59.818529 31.6801207,59.9872302 33.5,60 C48.1355457,60 60,48.1355457 60,33.5 C60,18.8644543 48.1355457,7 33.5,7 C29.7403617,7 26.1635861,7.78292871 22.9238205,9.19463825 C17.2938414,11.6478698 12.6815836,15.9999299 9.89598751,21.4418812 C8.04450583,25.0589428 7,29.1574931 7,33.5 C7,37.4400034 7.85985088,41.1791797 9.40213871,44.5401115 C10.5032233,46.9395795 11.9521427,49.1462562 13.6843386,51.0955887"
                      id="Oval"
                      stroke="#30345E"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeDasharray="0,0"
                      transform="translate(33.500000, 33.500000) rotate(170.000000) translate(-33.500000, -33.500000) "
                    ></path>
                    <path
                      d="M16.7973633,54.074707 C18.945483,55.8206875 21.3723197,57.2370059 24.0008068,58.2466011"
                      id="Oval"
                      stroke="#30345E"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeDasharray="0,0"
                    ></path>
                    <path
                      d="M57.5,56.5 L67,66"
                      id="Path-4"
                      stroke="#30345E"
                      strokeWidth="2.5"
                      strokeDasharray="0,0"
                    ></path>
                    <path
                      d="M66.0303757,71.8844404 L77.1156721,82.969738 C79.0083866,84.8624525 82.0770845,84.8624525 83.969799,82.969738 C85.8625135,81.0770235 85.8625135,78.0083256 83.969799,76.1156111 L72.8845015,65.0303147 C70.991787,63.1376002 67.9230902,63.1376002 66.0303757,65.0303147 C64.1376612,66.9230292 64.1376612,69.9917259 66.0303757,71.8844404 Z"
                      id="Rectangle"
                      stroke="#30345E"
                      strokeWidth="2.5"
                      fill="#F6F7FB"
                      strokeDasharray="0,0"
                    ></path>
                    <line
                      x1="71"
                      y1="67"
                      x2="82"
                      y2="78"
                      id="Line"
                      stroke="#FFFFFF"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeDasharray="0,0"
                    ></line>
                    <path
                      d="M48.0000305,43.0000343 C50.2783833,43.0000343 52.4719901,42.637207 54.5262299,41.9661827 C51.1731853,50.2870884 43.0225363,56.1607056 33.5000286,56.1607056 C20.9848843,56.1607056 10.8393555,46.0151787 10.8393555,33.5000343 C10.8393555,22.1595755 19.1697245,12.764778 30.0450306,11.1010742 C28.1129768,14.2793076 27.0000286,18.0096989 27.0000286,22.0000334 C27.0000286,33.5980129 36.402051,43.0000343 48.0000305,43.0000343 Z"
                      id="Oval-3"
                      fill="#F6F7FB"
                    ></path>
                    <path
                      d="M34,15 C32.7266093,15 31.4824779,15.1252697 30.2792549,15.3641577 M26.6447487,16.4760799 C19.8038659,19.3508191 15,26.114408 15,34 M30.3112702,15.3976232 C30.1923667,15.3976232 28.9701929,15.7571088 26.6447487,16.4760799 C19.8038659,19.3508191 15,26.114408 15,34"
                      id="Shape"
                      stroke="#30345E"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeDasharray="0,0"
                    ></path>
                  </g>
                  <path
                    d="M119.148438,26 L140.462058,26 L119.148438,26 Z M141.103027,26 L143.324219,26 L141.103027,26 Z M130,33.7773438 L121.824219,33.7773438"
                    id="Line-8"
                    stroke="#30345E"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="0,0"
                  ></path>
                  <path
                    d="M12.5973211,47 L34.1363949,47 L12.5973211,47 Z M8.10302734,47 L12.3242188,47 L8.10302734,47 Z M40,55.7773438 L31.8242188,55.7773438"
                    id="Line-8"
                    stroke="#30345E"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeDasharray="0,0"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </IconContainer>

        <Label>
          Try adjusting your search
          <br />
          to find what you are looking for
        </Label>
      </Content>
    </Container>
  );
};

export default QueryEmpty;
