import { useEffect } from "react";
import tw from "tailwind-styled-components";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "@c/modal";
import Notify from "@c/notify";
import RegularInput from "@b/inputs/regular";
import Button from "@c/button";
import formatInt from "@u/format-int";
import { useMakeOffer } from "@/hooks/use-loan";
import { useNavigate } from "react-router-dom";

const Container = tw.div`flex flex-col gap-y-5`;

const Form = tw.form`flex flex-col gap-y-5`;

const FormItem = tw.span`flex flex-col gap-y-2`;

const Label = tw.p`text-modal-make-offer-label`;

const Heading = tw.p`text-sm font-serif text-modal-make-offer-label`;

const MakeOffer = ({ loanid, partnerid, loanrequested }: IMakeOfferComp) => {
  const schema = yup
    .object({
      loan_id: yup.string().required().default(loanid),
      partner_id: yup.string().required().default(partnerid),
      approved_amount: yup.number().required(),
      equity: yup.number().required(),
      interest: yup.number().required(),
      tenure: yup.number().required(),
      monthly_repayment: yup.number().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IMakeOffer>({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, isSuccess, error } = useMakeOffer();

  const handleSignIn = (data: IMakeOffer) => {
    mutate(data);
  };

  useEffect(() => {
    isError &&
      error instanceof AxiosError &&
      Notify.error(JSON.parse(error.response?.data).message);
  }, [isError, error]);

  useEffect(() => {
    isSuccess && Notify.success("Offer has been successfully created.");
    isSuccess && navigate(`/loans/processed-application`);
    isSuccess && queryClient.invalidateQueries(["offers", partnerid]);
  }, [isSuccess, navigate, partnerid, queryClient]);

  return (
    <Modal
      header="Loan Approval"
      trigger={
        <Button isLoading={false} variant="secondary" width="w-[120px]">
          Approve
        </Button>
      }
      width="w-[400px]"
      content={
        <Container>
          <Heading>
            LOAN REQUESTED: {formatInt(Number(loanrequested), true) || "---"}
          </Heading>
          <Form onSubmit={handleSubmit(handleSignIn)} autoComplete="off">
            <FormItem>
              <Label>Approved Amount</Label>
              <RegularInput
                type="number"
                min="1"
                label="approved amount"
                {...register("approved_amount")}
                error={errors.approved_amount}
              />
            </FormItem>

            <FormItem>
              <Label>Equity</Label>
              <RegularInput
                type="number"
                min="1"
                label="equity"
                {...register("equity")}
                error={errors.equity}
              />
            </FormItem>

            <FormItem>
              <Label>Duration (months)</Label>
              <RegularInput
                type="number"
                min="1"
                label="duration"
                {...register("tenure")}
                error={errors.tenure}
              />
            </FormItem>

            <FormItem>
              <Label>Interest % (per annum)</Label>
              <RegularInput
                type="number"
                min="1"
                max="100"
                label="interest"
                {...register("interest")}
                error={errors.interest}
              />
            </FormItem>

            <FormItem>
              <Label>Monthly Repayment</Label>
              <RegularInput
                type="number"
                min="1"
                label="monthly"
                {...register("monthly_repayment")}
                error={errors.monthly_repayment}
              />
            </FormItem>

            <Button isLoading={isLoading} variant="secondary" width="w-full">
              Confirm Approval
            </Button>
          </Form>
        </Container>
      }
    />
  );
};

export default MakeOffer;
