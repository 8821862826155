import tw from "tailwind-styled-components";

const Container = tw.div`
${(props: IPlaceholderContainer) => props.width}
${(props: IPlaceholderContainer) =>
  props.color ? props.color : "bg-placeholder-scene"}
${(props: IPlaceholderContainer) => (props.align !== "center" ? "" : "mx-auto")}

h-[20.5px] animate-pulse rounded-sm`;

const Placeholder = ({ color, align }: IPlaceholder) => {
  const widths = ["w-[45px]", "w-[90px]", "w-[75px]", "w-[120px]", "w-[110px]"];

  const width = Math.floor(Math.random() * widths.length);

  return <Container width={widths[width]} color={color} align={align} />;
};

export default Placeholder;
