import { useEffect, useState } from "react";
import tw from "tailwind-styled-components";
import { AxiosError } from "axios";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "@c/modal";
import Notify from "@c/notify";
import RegularInput from "@b/inputs/regular";
import Button from "@c/button";
import { useMakeOffer } from "@/hooks/use-loan";
import { useNavigate } from "react-router-dom";

const Container = tw.div`flex flex-col gap-y-5`;

const Form = tw.form`flex flex-col gap-y-5`;

const FormItem = tw.span`flex flex-col gap-y-2`;

const Label = tw.p`text-modal-make-offer-label`;

const Select = tw.select`w-full h-12 border text-[15px] text-inputs-regular-input placeholder:text-slate-300 px-3 rounded-md outline-none border-gray-100`;

const Heading = tw.p`text-sm font-serif text-modal-make-offer-label`;

const RejectOffer = ({ loanid, partnerid }: IRejectOfferComp) => {
  const [reason, setReason] = useState("");

  const handleReasonChange = (e: any) => {
    setReason(e.target.value);
  };

  const schema = yup
    .object({
      loan_id: yup.string().required().default(loanid),
      partner_id: yup.string().required().default(partnerid),
      approved_amount: yup.number().required().default(0),
      equity: yup.number().required().default(0),
      interest: yup.number().required().default(0),
      tenure: yup.number().required().default(0),
      monthly_repayment: yup.number().required().default(0),
      reason_for_declination: yup.string().required(),
      reason_for_declination_description: yup.string(),
      is_rejected: yup.boolean().default(true),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRejectOffer>({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, isSuccess, error } = useMakeOffer();

  const handleSignIn = (data: IRejectOffer) => {
    mutate(data);
  };

  useEffect(() => {
    isError &&
      error instanceof AxiosError &&
      Notify.error(JSON.parse(error.response?.data).message);
  }, [isError, error]);

  useEffect(() => {
    isSuccess && Notify.success("Offer has been successfully rejected.");
    isSuccess && navigate(`/loans/processed-application`);
    isSuccess && queryClient.invalidateQueries(["offers", partnerid]);
  }, [isSuccess, navigate, partnerid, queryClient]);

  return (
    <Modal
      header="Reject Offer"
      trigger={
        <Button isLoading={false} variant="primary" width="w-[120px]">
          Reject
        </Button>
      }
      width="w-[400px]"
      content={
        <Container>
          <Heading>Are you sure you want to reject this loan?</Heading>
          <Form onSubmit={handleSubmit(handleSignIn)} autoComplete="off">
            <FormItem>
              <Label>Description</Label>
              <Select
                id="cars"
                {...register("reason_for_declination")}
                onChange={handleReasonChange}
                required
              >
                <option value="">Select description</option>
                <option value="DTI higher than 33%">DTI higher than 33%</option>
                <option value="Self employed customer">
                  Self employed customer
                </option>
                <option value="Customer location beyond coverage area">
                  Customer location beyond coverage area
                </option>
                <option value="Vehicle age greater than 12 years">
                  Vehicle age greater than 12 years
                </option>
                <option value="Low salary">Low salary</option>
                <option value="Non performing loan on record">
                  Non performing loan on record
                </option>
                <option value="Salary cannot be traced on bank statement">
                  Salary cannot be traced on bank statement
                </option>
                <option value="Credit Criteria not met">
                  Credit Criteria not met
                </option>
                <option value="No proof of stable monthly income">
                  No proof of stable monthly income
                </option>
                <option value="Other">Other</option>
              </Select>
            </FormItem>

            {reason === "Other" && (
              <FormItem>
                <Label>Reason for Rejection</Label>
                <RegularInput
                  type="text"
                  label="reject offer"
                  {...register("reason_for_declination_description", {
                    required: reason === "Other",
                  })}
                  error={errors.reason_for_declination_description}
                />
              </FormItem>
            )}

            <Button isLoading={isLoading} variant="secondary" width="w-full">
              Reject Offer
            </Button>
          </Form>
        </Container>
      }
    />
  );
};

export default RejectOffer;
