const formatDate = (fulldate: string): string => {
  if (
    fulldate !== undefined &&
    new Date(fulldate).toString() !== "Invalid Date"
  ) {
    let day, month, year;
    const date = new Date(fulldate);

    day = date.getDate().toString();
    month = (date.getMonth() + 1).toString();
    year = date.getFullYear().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return `${[day, month, year].join("-")} ${date.toLocaleTimeString(
      "en-US"
    )}`;
  }

  return "---";
};

export default formatDate;
