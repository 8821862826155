import tw from "tailwind-styled-components";
import { Menu } from "@headlessui/react";
import Avatar from "@c/avatar";
import Copy from "@c/copy";
import Dropdown from "@c/dropdown";
import Icon from "@c/icons";
import getInitial from "@u/get-initial";

const Label = tw.p`mx-3 text-sm text-gray-400 tracking-wide`;

const Container = tw.div`flex flex-row flex-auto items-center rounded-md bg-layout-aside-profile-scene gap-3 m-3 mt-2 p-4`;

const Details = tw.div`flex-1 overflow-auto`;

const Title = tw.p`text-lg text-white truncate overflow-hidden tracking-wide`;

const Description = tw.div`flex flex-row gap-x-1`;

const Identification = tw.div`text-sm text-gray-400 truncate overflow-hidden tracking-wide`;

const OptionItem = tw.div`flex flex-row justify-between px-4 py-5 cursor-pointer first:rounded-t-md last:rounded-b-md border-b last:border-b-transparent border-b-layout-aside-profile-options-item-border bg-layout-aside-profile-options-item-scene hover:bg-layout-aside-profile-options-item-hover-scene`;

const OptionLabel = tw.p`text-base leading-6 text-white font-regular whitespace-nowrap tracking-wide`;

const Profile = ({
  avatar,
  label,
  name,
  identification,
  options,
}: IProfile) => {
  return (
    <div>
      {label && <Label>{label.toUpperCase()}</Label>}

      <Container>
        {avatar && <Avatar label={getInitial(name)} />}

        <Details>
          <Title>{name || "---"}</Title>
          <Description>
            <Identification>ID: {identification || "---"}</Identification>
            {identification && (
              <Copy text={identification} copied={<Icon.ProfileCopied />}>
                <Icon.ProfileCopy />
              </Copy>
            )}
          </Description>
        </Details>

        {options !== undefined && options.length !== 0 && (
          <>
            <Dropdown
              trigger={<Icon.ProfileOptions />}
              content={options.map((option, index) => (
                <Menu.Item as={OptionItem} onClick={option.action} key={index}>
                  <OptionLabel>{option.label}</OptionLabel>
                  {option?.icon}
                </Menu.Item>
              ))}
              itemsStyle="absolute left-3 bottom-[101px] w-[264px] rounded-md bg-layout-aside-profile-options-scene"
            />
          </>
        )}
      </Container>
    </div>
  );
};

export default Profile;
