import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import tw from "tailwind-styled-components";
import shallow from "zustand/shallow";
import asideState from "@s/aside";
import Icon from "@c/icons";
import storage from "@l/storage";
import Profile from "@b/aside/profile";
import Item from "@b/aside/item";
import authState from "../states/auth";
import menus from "@cn/aside-menus.json";

const Content = tw.div`
${(props: IAsideContent) =>
  props.$isAsideVisible ? "duration-500" : "opacity-0 duration-200"}

h-screen flex flex-col justify-between gap-y-5 w-72`;

const Menus = tw.div`flex flex-grow items-start`;

const Items = tw.div`flex flex-col flex-[1_0_0] rounded-md mx-3 overflow-hidden`;

const Aside = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isVisible] = asideState((state) => [state.isVisible], shallow);
  const {
    id: userId,
    firstname: userFirstName,
    lastname: userLastName,
    partner,
  } = storage?.getUser() || {};
  const {
    id: partnerId,
    name: partnerName,
    // logoUrl: partnerLogoUrl,
  } = partner || {};

  const userFullName = `${userFirstName} ${userLastName}`;
  const setLogin = authState((state: IAuthState) => state.setLogin);

  const handleSignOut = () => {
    queryClient.clear();
    storage.clearAll();
    setLogin(false);
    navigate("/auth/sign-in", { replace: true });
  };

  return (
    <Content $isAsideVisible={isVisible}>
      <Profile name={partnerName} identification={partnerId} />

      <Menus>
        <Items>
          {menus.map((menu, index) => (
            <Item $to={menu.path} $subItem={menu.subMenu} key={index}>
              {menu.title}
            </Item>
          ))}
        </Items>
      </Menus>

      <Profile
        avatar
        label="Logged in as:"
        name={userFullName}
        identification={userId}
        options={[
          {
            label: "Sign Out",
            action: handleSignOut,
            icon: <Icon.ProfileLogout />,
          },
        ]}
      />
    </Content>
  );
};

export default Aside;
