import tw from "tailwind-styled-components";

const Container = tw.div`flex flex-col h-full w-full bg-white rounded-xl overflow-hidden`;

const Content = tw.div`overflow-scroll no-scrollbar border-b border-table-content-border h-full w-full`;

const Table = tw.table`w-full rounded-xl table-fixed`;

const TH = tw.th`py-3 text-md font-semibold bg-table-content-th-scene text-table-content-th-label sticky top-0`;

const TR = tw.tr`cursor-pointer border-b border-table-content-tr-border last:border-transparent hover:bg-table-content-tr-hover-scene`;

const Tbody = tw.tbody`text-center`;

const TD = tw.td`py-5 text-sm text-table-content-td-label w-72`;

Table.Container = Container;
Table.Content = Content;
Table.TH = TH;
Table.TR = TR;
Table.Tbody = Tbody;
Table.TD = TD;

export default Table;
