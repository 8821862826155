import { Component, ErrorInfo } from "react";
import * as Sentry from "@sentry/react";
import ErrorOcurred from "@/pages/errors/error-ocurred";

class ErrorBoundary extends Component<IErrorBoundary, IErrorBoundaryState> {
  public state: IErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): IErrorBoundaryState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException({ error, errorInfo });
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorOcurred />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
